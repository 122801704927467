export function LockIcon() {
  return (
    <svg
      width="96"
      height="172"
      viewBox="0 0 96 172"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.4 90.7l95.1-35.5v70.6c.3 2.3-.9 4.5-3.7 6.1l-64.3 37.3c-5.6 3.2-15.3 2.8-21.7-1-3.4-2-5.2-4.4-5.3-6.8V90.7"
        fill="#f7b554"
      />
      <path
        d="M27.5 98.3L91.8 61c5.6-3.2 4.9-8.8-1.5-12.5-6.5-3.7-16.2-4.1-21.8-1L4.2 84.9c-5.6 3.3-4.9 8.9 1.6 12.6 6.4 3.7 16.1 4.1 21.7.9"
        fill="#ffd27d"
      />
      <path
        d="M41.6 4.3A27 27 0 0182 27.7v29.2c0 2.9-4 5.2-8.8 5.2-4.9 0-8.9-2.3-8.9-5.2V31.3c0-8.8-9.5-14.3-17.1-9.9a28.5 28.5 0 00-14.3 24.7v39h-.1c-.1 2.8-4 5-8.9 5-4.8 0-8.8-2.3-8.8-5v-35A53 53 0 0141.6 4.3"
        fill="#ada2e5"
      />
      <path
        d="M42.2 17.8l1.6-.8a31 31 0 00-13.7 25.7v45.7c-1 .6-2.1 1-3.4 1.3v-45c0-11.1 5.9-21.4 15.5-26.9"
        fill="#e0dcff"
      />
      <path
        d="M60.1 13.1c8 0 14.5 6.5 14.5 14.5v33.7c.1.2-5-.2-5-.6V27c0-6.5-4.4-12-10.3-13.9h.8"
        fill="#ada2e5"
      />
      <path
        d="M51.4 6.9a28 28 0 00-13.1 3.3c1.5-1.2 3.2-2.3 4.9-3.3A24 24 0 0179 27.6v.1A29 29 0 0051.4 7"
        fill="#e0dcff"
      />
      <path
        d="M22 55.6v34.2a11 11 0 01-4.5-1.6 3 3 0 01-.3-1.2V52.2c0-15.1 4.7-12 4.7 3.4"
        fill="#ada2e5"
      />
      <path
        d="M47.3 110.8c0-5.6 4-12.3 8.8-15 4.8-2.8 8.7-.6 8.7 4.9 0 3.7-1.8 8-4.4 11.2v17.6l-8.7 5v-17.6c-2.6-.2-4.4-2.4-4.4-6.1"
        fill="#946520"
      />
      <path
        d="M58.9 128.8v-17.5a19 19 0 004.4-11.2c0-2.6-.9-4.5-2.3-5.5 2.3.5 3.8 2.7 3.8 6.1 0 3.7-1.8 8-4.4 11.2v17.6l-8.7 5v-1.6l7.2-4.1"
        fill="#ffd27d"
      />
    </svg>
  );
}
