import { ReactNode } from "react";

import { Box, Text } from "ui";

export type LabelProps = {
  label?: string | ReactNode;
  value?: string | ReactNode;
  color?: string;
};

export function Label({ label, value, color }: LabelProps) {
  return (
    <Box>
      <Text fontSize={1} fontWeight={300}>
        {label}
      </Text>
      <Text fontWeight="bold" fontSize={[3, 4, 5]} color={color}>
        {value}
      </Text>
    </Box>
  );
}
