import { ReactNode } from "react";

import { Box, Button, Text } from "ui";

import { useAuth } from "../../hooks/useAuth";
import { LockIcon } from "../LockIcon";

export interface AuthWallProps {
  children?: ReactNode;
}

export function AuthWall({ children }: AuthWallProps) {
  const { loading, user, signIn } = useAuth();

  if (loading) {
    return null;
  }

  if (!user) {
    return (
      <Box flexDirection={["column", "row"]} alignItems="center">
        <LockIcon />

        <Box margin={4}>
          <Box marginBottom={3}>
            <Text as="h1" variant="heading" marginBottom={3}>
              Restricted Area
            </Text>
            <Text as="p" fontSize={1}>
              Authentication is required to continue.
              <br />
              Please sign in using your GitHub credentials.
            </Text>
          </Box>
          <Button onClick={signIn}>Sign In</Button>
        </Box>
      </Box>
    );
  }

  return <>{children}</>;
}
