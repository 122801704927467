import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDYLLXVARG-IcW0hUL3WGypjzbcBWk4GaI",
  authDomain: "cashdash-4174a.firebaseapp.com",
  projectId: "cashdash-4174a",
  storageBucket: "cashdash-4174a.appspot.com",
  messagingSenderId: "765535340879",
  appId: "1:765535340879:web:c31c76177e8a923c539d3a",
};

firebase.initializeApp(firebaseConfig);
