import { useCallback, useEffect, useState } from "react";

type AsyncFn<T, A> = (...args: A[]) => Promise<T>;

export function useAsync<T, A>(asyncFunction: AsyncFn<T, A>, immediate = true) {
  const [loading, setLoading] = useState(immediate);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, data, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args: A[]) => {
      setLoading(true);

      return asyncFunction(...args)
        .then((response: T) => {
          setData(response);
          setError(null);
          setLoading(false);
          return response;
        })
        .catch((error: any) => {
          setData(undefined);
          setError(error);
          setLoading(false);
        });
    },
    [asyncFunction]
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, loading, data, error };
}
