import { FormattedNumber } from "react-intl";
import { VictoryPie, VictoryChart, VictoryAxis } from "victory";

import { Box } from "ui";

import { CustomVictoryTheme } from "../../victory-theme";
import { Label } from "../Label";
import { DashboardInterest } from "../../domain/dashboard/models/DashboardData";

export interface InterestSourceChartProps {
  data: DashboardInterest;
  currency: string;
}

export function InterestSourceChart({
  data,
  currency,
}: InterestSourceChartProps) {
  return (
    <Box flexDirection={["column", "row"]} alignItems="center">
      <Box width={[1, 1 / 2]}>
        <VictoryChart
          theme={CustomVictoryTheme}
          padding={{
            top: 24,
            bottom: 24,
          }}
          width={480}
          height={360}
        >
          <VictoryAxis
            style={{
              axis: {
                display: "none",
              },
              ticks: {
                display: "none",
              },
              tickLabels: {
                display: "none",
              },
            }}
          />

          <VictoryPie
            data={data.current.bySource}
            x="Source"
            y="Amount"
            innerRadius={72}
            startAngle={120}
            endAngle={360 + 120}
          />
        </VictoryChart>
      </Box>
      <Box
        width={[1, 1 / 2]}
        paddingX={3}
        flexDirection={["row", "column"]}
        justifyContent="space-between"
      >
        <Label
          label="Last month interest"
          value={
            <FormattedNumber
              value={data.current.lastMonthInterest}
              style="currency"
              currency={currency}
            />
          }
        />
        <Label
          label="Average monthly interest"
          value={
            <FormattedNumber
              value={data.current.averageMonthly}
              style="currency"
              currency={currency}
            />
          }
        />
      </Box>
    </Box>
  );
}
