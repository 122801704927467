import { FormattedNumber, useIntl } from "react-intl";
import { VictoryPie, VictoryChart, VictoryAxis, VictoryLegend } from "victory";

import { Box } from "ui";

import { CustomVictoryTheme } from "../../victory-theme";
import { Label } from "../Label";
import { DashboardExpenses } from "../../domain/dashboard/models/DashboardData";

export interface ExpensesPieChartProps {
  data: DashboardExpenses;
  currency: string;
}

export function ExpensesPieChart({ data, currency }: ExpensesPieChartProps) {
  const intl = useIntl();

  return (
    <Box flexDirection={["column", "row"]} alignItems="center">
      <Box
        width={[1, 1 / 2]}
        paddingX={3}
        flexDirection={["row", "column"]}
        justifyContent="space-between"
        alignItems="flex-end"
        textAlign="right"
      >
        <Label
          label="Last month expenses"
          value={
            <FormattedNumber
              value={data.current.lastMonth}
              style="currency"
              currency={currency}
            />
          }
        />
        <Label
          label="Average monthly expenses"
          value={
            <FormattedNumber
              value={data.current.averageMonthly}
              style="currency"
              currency={currency}
            />
          }
        />
      </Box>

      <Box width={[1, 1 / 2]}>
        <VictoryChart
          theme={CustomVictoryTheme}
          padding={{
            top: 48,
            bottom: 106,
          }}
          width={480}
          height={420}
        >
          <VictoryAxis
            style={{
              axis: {
                display: "none",
              },
              ticks: {
                display: "none",
              },
              tickLabels: {
                display: "none",
              },
            }}
          />

          <VictoryPie
            data={data.current.bySource}
            x="Source"
            y="Amount"
            innerRadius={72}
            labels={({ datum }) =>
              intl.formatNumber(datum.Amount, { style: "currency", currency })
            }
          />

          <VictoryLegend
            data={data.current.bySource.map((row) => ({ name: row.Source }))}
            orientation="horizontal"
            itemsPerRow={3}
            x={480 / 2 - 160}
            y={420 - 63}
          />
        </VictoryChart>
      </Box>
    </Box>
  );
}
