import { Theme } from "styled-system";

const colors = {
  primary: "#2d98da",
  positive: "#20bf6b",
  negative: "#eb3b5a",
  alert: "#fa8231",
};

const theme: Theme = {
  colors: {
    ...colors,
    chart: ["#8854d0", "#2d98da", "#20bf6b", "#c0b149", "#fa8231", "#eb3b5a"],
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: ["90%", 590, 800, 960],
  },
  buttons: {
    default: {
      background: "#4b6584",
      color: "#fff",
    },
    primary: {
      background: colors.primary,
      color: "#fff",
    },
    link: {
      background: "transparent",
      color: "#fff",
    },
  },
  textStyles: {
    heading: {
      fontSize: "5",
      fontWeight: "bold",
    },
  },
};

export default theme;
