import { useIntl } from "react-intl";
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryTooltip,
} from "victory";

import { useTheme, get } from "ui";

import { CustomVictoryTheme } from "../../victory-theme";
import { VictoryLine } from "victory";
import { generateTicks } from "../../utils/generateTicks";
import { DashboardInterest } from "../../domain/dashboard/models/DashboardData";

export interface MonthlyInterestChartProps {
  data: DashboardInterest;
  currency: string;
}

export function MonthlyInterestChart({ data }: MonthlyInterestChartProps) {
  const theme = useTheme();

  const intl = useIntl();

  const chartColor = get(theme, "colors.chart.1");

  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="MonthlyInterestChartGradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={chartColor} stopOpacity="1" />
            <stop offset="100%" stopColor={chartColor} stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

      <VictoryChart
        theme={CustomVictoryTheme}
        padding={{
          top: 24,
          bottom: 24,
        }}
        width={960}
        height={180}
        containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
      >
        <VictoryAxis
          scale={{ x: "time" }}
          tickFormat={(x) =>
            intl.formatDate(x, { month: "short", year: "2-digit" })
          }
          tickValues={generateTicks(data.timeSeries.length, 6, 2).map(
            (tick) => data.timeSeries[tick].Month
          )}
        />

        <VictoryArea
          data={data.timeSeries}
          x="Month"
          y="Amount"
          interpolation="monotoneX"
          style={{
            data: {
              fill: "url(#MonthlyInterestChartGradient)",
              opacity: 0.2,
            },
          }}
        />
        <VictoryLine
          data={data.timeSeries}
          x="Month"
          y="Amount"
          interpolation="monotoneX"
          style={{
            data: {
              stroke: chartColor,
              strokeWidth: 4,
            },
          }}
        />
        <VictoryScatter
          data={data.timeSeries}
          x="Month"
          y="Amount"
          style={{
            data: {
              fill: "#31445a",
              stroke: chartColor,
              strokeWidth: 2,
            },
          }}
          size={4}
          labels={({ datum }) =>
            `${intl.formatDate(datum.Month)}: ${intl.formatNumber(
              datum.Amount,
              {
                style: "currency",
                currency: "BRL",
              }
            )}`
          }
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </>
  );
}
