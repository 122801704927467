import { forwardRef } from "react";
import { darken } from "polished";
import { get } from "styled-system";

import { Box, BoxProps } from "./Box";

export interface ButtonKnownProps extends BoxProps {}

export interface ButtonProps
  extends ButtonKnownProps,
    Omit<React.HTMLProps<HTMLButtonElement>, keyof ButtonKnownProps> {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const bg = (theme: any) =>
      get(theme, `buttons.${props.variant || "default"}.background`, "#fff");

    return (
      <Box
        ref={ref}
        as="button"
        tx="buttons"
        variant="default"
        {...props}
        __css={{
          appearance: "none",
          border: "none",
          cursor: "pointer",
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingX: 3,
          paddingY: 2,
          fontWeight: "bold",
          borderRadius: 4,
          boxShadow: (theme: any) =>
            `${darken(0.1, bg(theme))} 0px 6px 0px 0px`,
          transition: "all 100ms cubic-bezier(0.5, 1, 0.89, 1) 0s",
          position: "relative",
          top: "0px",
          "&:active": {
            boxShadow: (theme: any) =>
              `${darken(0.1, bg(theme))} 0px 3px 0px 0px`,
            top: "4px",
          },
          "&:hover": {
            background: (theme: any) => `${darken(0.05, bg(theme))}`,
          },
          "&:focus": {
            background: (theme: any) => `${darken(0.05, bg(theme))}`,
          },
        }}
      />
    );
  }
);
