import { DataFrame, IDataFrame } from "data-forge";

import { Book, Split, Transaction } from "../book";
import { getAccountFullName } from "./getAccountFullName";
import { SplitRow } from "./models/SplitRow";

export type SplitsDataFrame = IDataFrame<string, SplitRow>;

export function buildDataFrame(book: Book): SplitsDataFrame {
  const rows = book.transactions.flatMap((transaction: Transaction) => {
    return transaction.splits.map((split: Split) => {
      return [
        split.id,
        transaction.datePosted,
        transaction.id,
        transaction.description,
        getAccountFullName(split.account),
        split.account.type,
        `${split.account.commodity.space}:${split.account.commodity.id}`,
        split.quantity,
      ];
    });
  });

  const df = new DataFrame({
    columnNames: [
      "SplitId",
      "Timestamp",
      "TransactionId",
      "Description",
      "AccountName",
      "AccountType",
      "Commodity",
      "Amount",
    ],
    rows,
  });

  return df.withIndex(df.getSeries<string>("SplitId")).dropSeries("SplitId");
}
