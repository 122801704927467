import { system } from "styled-system";

export interface TransitionProps {
  transitionProperty?: string;
  transitionTimingFunction?: string;
  transitionDuration?: string;
}

export const transition = system({
  transitionProperty: {
    property: "transitionProperty",
    scale: "transitions.property",
  },
  transitionDuration: {
    property: "transitionDuration",
    scale: "transitions.duration",
  },
  transitionTimingFunction: {
    property: "transitionTimingFunction",
    scale: "transitions.timingFunction",
  },
});
