export function generateTicks(length: number, count: number, offset = 0) {
  const len = length - offset * 2;
  const interval = Math.floor(len / (count - 1));

  const ticks = [];

  for (let i = offset; i < length - offset; i += interval) {
    ticks.push(i);
  }

  return ticks;
}
