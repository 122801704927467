import { SplitRow } from "../dataframe";

export function getAccountCategory(
  row: Pick<SplitRow, "AccountName" | "AccountType">
) {
  if (row.AccountName.startsWith("Ativos.Circulantes")) {
    return "CurrentAssets";
  }
  if (row.AccountName.startsWith("Ativos.Permantes")) {
    return "FixedAssets";
  }
  if (row.AccountType === "LIABILITY") {
    return "Liabilities";
  }
  if (row.AccountType === "INCOME") {
    return "Income";
  }
  if (row.AccountType === "EXPENSE") {
    return "Expenses";
  }

  return "Other";
}
