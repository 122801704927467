import { useCallback } from "react";
import firebase from "firebase/app";

import { Container, Loader } from "ui";

import { Dashboard } from "../Dashboard";
import { useAsync } from "../../hooks/useAsync";
import { buildDataFrame } from "../../domain/dataframe";
import { parseBook } from "../../domain/book";

const storage = firebase.storage();

export function Main() {
  const loadFromGithub = useCallback(async () => {
    const bookRef = storage.ref("lefluxe.gnucash");
    const bookUrl = await bookRef.getDownloadURL();
    const response = await fetch(bookUrl);
    const file = await response.text();

    const book = await parseBook(file);

    return buildDataFrame(book);
  }, []);

  const { data: dataFrame, loading, error } = useAsync(loadFromGithub);

  return (
    <Container height="100%" alignItems="center" justifyContent="center">
      <Loader active={loading} />

      {error && <p>Error while loading the dashboard. Try again later.</p>}

      {dataFrame && <Dashboard dataFrame={dataFrame} />}
    </Container>
  );
}
