import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { get } from "styled-system";

import { Box, useTheme } from "ui";

import { Navigation } from "../Navigation";
import { TotalAssetsChart } from "../TotalAssetsChart";
import { CurrentAssetsChart } from "../CurrentAssetsChart";
import { IncomeExpensesChart } from "../IncomeExpensesChart";
import { MonthlyInterestChart } from "../MonthlyInterestChart";
import { InterestSourceChart } from "../InterestSourceChart";
import { buildDashboardData } from "../../domain/dashboard";
import { SplitsDataFrame } from "../../domain/dataframe";
import { generateDates } from "../../utils/generateDates";
import { ExpensesPieChart } from "../ExpensesPieChart";

export interface DashboardProps {
  currency?: string;
  dataFrame: SplitsDataFrame;
}

export function Dashboard({ currency = "BRL", dataFrame }: DashboardProps) {
  const intl = useIntl();

  const theme = useTheme();

  const [activeMonth, setActiveMonth] = useState<number>();

  const months = useMemo(() => {
    const ts = dataFrame.getSeries<Date>("Timestamp").orderBy((date) => date);
    return generateDates(ts.first(), ts.last(), "month");
  }, [dataFrame]);

  const data = useMemo(() => {
    const maxDate =
      typeof activeMonth !== "undefined" ? months[activeMonth] : undefined;

    return buildDashboardData(dataFrame, maxDate);
  }, [dataFrame, months, activeMonth]);

  useEffect(() => {
    const initialMonth = months.length - 2;
    setActiveMonth(initialMonth <= 0 ? 0 : initialMonth);
  }, [months]);

  return (
    <Box width="100%" borderRadius={12} marginBottom={4}>
      <Navigation
        items={months.map((month) => {
          const color = getMonthColor(month);
          return {
            label: intl.formatDate(month, { month: "short" }),
            value: month,
            color: color && get(theme, `colors.${color}`, "#cecece"),
          };
        })}
        activeIndex={activeMonth}
        onNavigate={setActiveMonth}
      />
      <Box
        backgroundColor="#31445A"
        paddingBottom={[3, 4]}
        marginTop={4}
        paddingTop={5}
        borderBottomLeftRadius={12}
        borderBottomRightRadius={12}
        boxShadow="0 8px 12px -2px rgba(0, 0, 0, 0.2)"
      >
        <TotalAssetsChart data={data.assets} currency={currency} />
        <CurrentAssetsChart data={data.assets} currency={currency} />
        <IncomeExpensesChart data={data.incomeExpenses} currency={currency} />
        <ExpensesPieChart data={data.expenses} currency={currency} />
        <InterestSourceChart data={data.interest} currency={currency} />
        <MonthlyInterestChart data={data.interest} currency={currency} />
      </Box>

      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        background="linear-gradient(#2c3a47 0%, #2c3a47 80%, #2c3a4700 100%)"
        height={72}
      ></Box>
    </Box>
  );
}

function getMonthColor(value: Date): "primary" | "alert" | undefined {
  const month = moment(value);
  const now = moment().startOf("month").subtract(1, "day");

  if (month.isSame(now, "month")) {
    return "primary";
  }

  if (month.isAfter(now, "month")) {
    return "alert";
  }
}
