import moment from "moment";

export function generateDates(
  startDate: Date,
  endDate: Date,
  interval: moment.unitOfTime.Base
): Date[] {
  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate).endOf(interval);
  const months = [];
  while (mEndDate >= mStartDate) {
    months.push(mStartDate.endOf(interval).toDate());
    mStartDate.add(1, interval);
  }

  return months;
}
