import moment from "moment";
import { IDataFrame } from "data-forge";

import {
  DashboardData,
  DashboardExpenses,
  DashboardInterest,
} from "./models/DashboardData";
import { SplitsDataFrame } from "../dataframe";
import { getAccountCategory } from "./getAccountCategory";

export function buildDashboardData(
  dataFrame: SplitsDataFrame,
  endDate?: Date
): DashboardData {
  const orderedDataFrame = dataFrame.orderBy((row) => row.Timestamp);

  const filteredDataFrame = orderedDataFrame.where(
    (row) => !endDate || row.Timestamp <= endDate
  );

  const monthly = filteredDataFrame
    .groupBy((row) => [
      moment(row.Timestamp).endOf("month").toDate(),
      row.AccountName,
      row.AccountType,
    ])
    .select((group) => ({
      Month: moment(group.first().Timestamp).endOf("month").toDate(),
      AccountName: group.first().AccountName,
      AccountType: group.first().AccountType,
      Category: getAccountCategory(group.first()),
      Amount: group.getSeries("Amount").sum(),
    }))
    .inflate();

  return {
    assets: buildAssets(monthly),
    incomeExpenses: buildIncomeExpenses(monthly),
    interest: buildInterest(monthly),
    expenses: buildExpenses(monthly),
  };
}

function buildAssets(
  data: IDataFrame<
    number,
    {
      Month: Date;
      Category: string;
      Amount: number;
    }
  >
) {
  const currentAssets = data
    .where((row) => row.Category === "CurrentAssets")
    .getSeries("Amount")
    .sum();

  const fixedAssets = data
    .where((row) => row.Category === "FixedAssets")
    .getSeries("Amount")
    .sum();

  const liabilities = data
    .where((row) => row.Category === "Liabilities")
    .getSeries("Amount")
    .sum();

  const netAssetsTimeSeries = data
    .where(
      (row) =>
        row.Category === "CurrentAssets" ||
        row.Category === "FixedAssets" ||
        row.Category === "Liabilities"
    )
    .groupBy((row) => row.Month)
    .select((group) => ({
      Month: group.first().Month,
      Amount: group.getSeries("Amount").sum(),
    }))
    .inflate()
    .aggregate<{
      rows: { x: Date; y: number }[];
      sum: number;
    }>(
      {
        rows: [],
        sum: 0,
      },
      (agg, row) => {
        return {
          rows: [
            ...agg.rows,
            {
              x: row.Month,
              y: agg.sum + row.Amount,
            },
          ],
          sum: agg.sum + row.Amount,
        };
      }
    ).rows;

  const currentAssetsTimeSeries = data
    .where((row) => row.Category === "CurrentAssets")
    .groupBy((row) => row.Month)
    .select((group) => ({
      Month: group.first().Month,
      Amount: group.getSeries("Amount").sum(),
    }))
    .aggregate(
      {
        rows: [],
        sum: 0,
      },
      (agg: any, row) => {
        return {
          rows: [
            ...agg.rows,
            {
              x: row.Month,
              y: agg.sum + row.Amount,
            },
          ],
          sum: agg.sum + row.Amount,
        };
      }
    ).rows;

  return {
    current: {
      currentAssets,
      fixedAssets,
      liabilities,
    },
    timeSeries: {
      netAssets: netAssetsTimeSeries,
      currentAssets: currentAssetsTimeSeries,
    },
  };
}

function buildIncomeExpenses(
  data: IDataFrame<
    number,
    {
      Month: Date;
      Category: string;
      Amount: number;
    }
  >
) {
  const grouped = data
    .groupBy((row) => row.Month)
    .select((group) => ({
      Month: group.first().Month,
      Income: -group
        .where((row) => row.Category === "Income")
        .getSeries("Amount")
        .sum(),
      Expenses: group
        .where((row) => row.Category === "Expenses")
        .getSeries("Amount")
        .sum(),
    }))
    .inflate();

  const averageIncome = grouped.getSeries("Income").average();
  const averageExpenses = grouped.getSeries("Expenses").average();
  const averageSavings = averageIncome - averageExpenses;

  const timeSeries = grouped.tail(18).toArray();

  return {
    current: {
      averageIncome,
      averageExpenses,
      averageSavings,
    },
    timeSeries,
  };
}

function buildInterest(
  data: IDataFrame<
    number,
    {
      Month: Date;
      AccountName: string;
      Amount: number;
    }
  >
): DashboardInterest {
  const matcher = /^Receita\.([^.]+)\.Rendimentos\.(.+)$/;

  const withSource = data
    .select((row) => {
      const match = row.AccountName.match(matcher);
      return {
        ...row,
        Source: match?.[2],
      };
    })
    .where((row) => Boolean(row.Source));

  const monthly = withSource
    .groupBy((row) => row.Month)
    .select((group) => {
      return {
        Month: group.first().Month,
        Amount: -group.getSeries("Amount").sum(),
      };
    })
    .inflate();

  const lastMonthInterest = monthly.last().Amount;
  const averageMonthly = monthly.getSeries("Amount").average();
  const bySource = withSource
    .groupBy((row) => row.Source)
    .select((group) => {
      return {
        Source: group.first().Source || "",
        Amount: -group.getSeries("Amount").sum(),
      };
    })
    .inflate()
    .toArray();

  const timeSeries = monthly.toArray();

  return {
    current: {
      lastMonthInterest,
      averageMonthly,
      bySource,
    },
    timeSeries,
  };
}

function buildExpenses(
  data: IDataFrame<
    number,
    {
      Month: Date;
      AccountType: string;
      AccountName: string;
      Amount: number;
    }
  >
): DashboardExpenses {
  const filtered = data
    .where((row) => row.AccountType === "EXPENSE")
    .select((row) => {
      const parts = row.AccountName.split(".");
      const source = parts.length > 2 ? `${parts[1]}.${parts[2]}` : parts[1];
      return {
        ...row,
        Source: source,
      };
    });

  const monthly = filtered
    .groupBy((row) => row.Month)
    .select((group) => {
      return {
        Month: group.first().Month,
        Amount: group.getSeries("Amount").sum(),
      };
    })
    .inflate();

  const lastMonth = monthly.last().Amount;
  const averageMonthly = monthly.getSeries("Amount").average();
  const bySource = filtered
    .groupBy((row) => row.Month)
    .last()
    .groupBy((row) => row.Source)
    .select((group) => {
      return {
        Source: group.first().Source || "",
        Amount: group.getSeries("Amount").sum(),
      };
    })
    .inflate()
    .orderByDescending((row) => row.Amount);

  const maxSources = 5;
  const bySourceLimited = [
    ...bySource.take(maxSources).toArray(),
    {
      Source: "etc.",
      Amount: bySource.skip(maxSources).getSeries("Amount").sum(),
    },
  ];

  const timeSeries = monthly.toArray();

  return {
    current: {
      lastMonth,
      averageMonthly,
      bySource: bySourceLimited,
    },
    timeSeries,
  };
}
