import { FormattedNumber, useIntl } from "react-intl";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
  VictoryTooltip,
} from "victory";

import { Box, useTheme, get } from "ui";

import { CustomVictoryTheme } from "../../victory-theme";
import { generateTicks } from "../../utils/generateTicks";
import { Label } from "../Label";
import { DashboardIncomeExpenses } from "../../domain/dashboard";

export interface IncomeExpensesChartProps {
  data: DashboardIncomeExpenses;
  currency: string;
}

export function IncomeExpensesChart({
  data,
  currency,
}: IncomeExpensesChartProps) {
  const theme = useTheme();

  const intl = useIntl();

  return (
    <>
      <Box
        flexDirection={["column", "row"]}
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
        paddingX={[2, 4]}
        marginTop={[3, 4]}
      >
        <Label
          label="Average income"
          color={get(theme, "colors.positive")}
          value={
            <FormattedNumber
              value={data.current.averageIncome}
              style="currency"
              currency={currency}
            />
          }
        />

        <Label
          label="Average expenses"
          color={get(theme, "colors.negative")}
          value={
            <FormattedNumber
              value={data.current.averageExpenses}
              style="currency"
              currency={currency}
            />
          }
        />

        <Label
          label="Average savings"
          color={get(theme, "colors.primary")}
          value={
            <FormattedNumber
              value={data.current.averageSavings}
              style="currency"
              currency={currency}
            />
          }
        />
      </Box>

      <VictoryChart
        theme={CustomVictoryTheme}
        padding={{
          top: 24,
          bottom: 24,
        }}
        width={960}
        height={360}
      >
        <VictoryAxis
          scale={{ x: "time" }}
          tickFormat={(x) =>
            intl.formatDate(x, { month: "short", year: "2-digit" })
          }
          tickValues={generateTicks(data.timeSeries.length, 6, 1).map(
            (tick) => data.timeSeries[tick].Month
          )}
        />

        <VictoryGroup
          offset={16}
          colorScale={[
            get(theme, "colors.positive"),
            get(theme, "colors.negative"),
          ]}
          labelComponent={<VictoryTooltip />}
        >
          <VictoryBar
            data={data.timeSeries}
            x="Month"
            y="Income"
            labels={({ datum }) =>
              `${intl.formatDate(datum.Month)}: ${intl.formatNumber(
                datum.Income,
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`
            }
            cornerRadius={1}
          />
          <VictoryBar
            data={data.timeSeries}
            x="Month"
            y="Expenses"
            labels={({ datum }) =>
              `${intl.formatDate(datum.Month)}: ${intl.formatNumber(
                datum.Expenses,
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}`
            }
            cornerRadius={1}
          />
        </VictoryGroup>
      </VictoryChart>
    </>
  );
}
