import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";

import theme from "./theme";
import { Main } from "./components/Main";
import { AuthWall } from "./components/AuthWall";

export function App() {
  return (
    <IntlProvider locale="pt-BR">
      <ThemeProvider theme={theme}>
        <AuthWall>
          <Main />
        </AuthWall>
      </ThemeProvider>
    </IntlProvider>
  );
}
