import { forwardRef } from "react";
import { get } from "styled-system";

import { Box, BoxProps } from "./Box";

export interface ContainerKnownProps extends BoxProps {}

export interface ContainerProps
  extends ContainerKnownProps,
    Omit<React.HTMLProps<HTMLDivElement>, keyof ContainerKnownProps> {}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (props, ref) => {
    const width = (theme: any) =>
      get(theme, "sizes.container") || ["90%", 590, 800, 960];

    return (
      <Box
        ref={ref}
        {...props}
        __css={{
          width: width,
        }}
      />
    );
  }
);
