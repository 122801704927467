import { useEffect, useState } from "react";
import { Box } from "./Box";

export interface LoaderProps {
  active?: boolean;
}

export function Loader({ active = true }: LoaderProps) {
  const [visible, setVisible] = useState(active);
  const [opacity, setOpacity] = useState(active ? 1 : 0);

  useEffect(() => {
    if (active) {
      setVisible(true);
      setTimeout(() => {
        setOpacity(1);
      }, 1);
    } else {
      setOpacity(0);
      setTimeout(() => {
        setVisible(false);
      }, 300);
    }
  }, [active]);

  return (
    <Box
      display={visible ? "flex" : "none"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"fixed"}
      top={0}
      right={0}
      bottom={0}
      left={0}
      background={"rgba(0, 0, 0, 0.6)"}
      opacity={opacity}
      zIndex={10}
      transitionProperty="opacity"
      transitionDuration="300"
      transitionTimingFunction="out"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 44 44"
        stroke="#fff"
      >
        <g fill="none" fillRule="evenodd" strokeWidth="2">
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="-0.9s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="-0.9s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </Box>
  );
}
