import styled, { StyledComponent } from "styled-components";
import css, { get, SystemStyleObject } from "@styled-system/css";
import shouldForwardProp from "@styled-system/should-forward-prop";
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  background,
  border,
  position,
  shadow,
  FlexboxProps,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  BackgroundProps,
} from "styled-system";

import { transition, TransitionProps } from "../styles/transition";

const sx = (props: any) => css(props.sx)(props.theme);
const base = (props: any) => css(props.__css)(props.theme);
const variant = ({ theme, variant, tx = "variants" }: any) =>
  css(get(theme, tx + "." + variant, get(theme, variant)))(theme);

export interface BoxProps
  extends FlexboxProps,
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    BackgroundProps,
    BorderProps,
    PositionProps,
    ShadowProps,
    TransitionProps {
  as?: React.ElementType;
  sx?: SystemStyleObject;
  tx?: string;
  variant?: string;
  __css?: SystemStyleObject;
}

export type BoxType = StyledComponent<
  "div",
  any,
  Omit<
    JSX.IntrinsicElements["div"] & BoxProps,
    keyof React.ClassAttributes<any>
  >
>;

export const Box: BoxType = styled.div.withConfig({
  shouldForwardProp,
})<BoxProps>(
  {
    boxSizing: "border-box",
    margin: 0,
    minWidth: 0,
    display: "flex",
    flexDirection: "column",
  },
  base,
  variant,
  sx,
  (props: any) => props.css,
  compose(
    flexbox,
    space,
    color,
    typography,
    layout,
    background,
    border,
    position,
    shadow,
    transition
  )
);
