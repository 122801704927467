import { useIntl, FormattedNumber } from "react-intl";
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryTooltip,
} from "victory";

import { Box, useTheme, get } from "ui";

import { CustomVictoryTheme } from "../../victory-theme";
import { generateTicks } from "../../utils/generateTicks";
import { DashboardAssets } from "../../domain/dashboard";
import { Label } from "../Label";

export interface TotalAssetsChartProps {
  data: DashboardAssets;
  currency: string;
}

export function TotalAssetsChart({ data, currency }: TotalAssetsChartProps) {
  const theme = useTheme();

  const intl = useIntl();

  const timeSeries = data.timeSeries.netAssets;

  return (
    <>
      <Box
        flexDirection={["column", "row"]}
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
        paddingX={[2, 4]}
        marginTop={[3, 4]}
      >
        <Label
          label="Net Assets"
          value={
            <FormattedNumber
              value={
                data.current.currentAssets +
                data.current.fixedAssets +
                data.current.liabilities
              }
              style="currency"
              currency={currency}
            />
          }
        />
        <Label
          label="Assets"
          color={get(theme, "colors.positive")}
          value={
            <FormattedNumber
              value={data.current.currentAssets + data.current.fixedAssets}
              style="currency"
              currency={currency}
            />
          }
        />
        <Label
          label="Liabilities"
          color={get(theme, "colors.negative")}
          value={
            <FormattedNumber
              value={-data.current.liabilities}
              style="currency"
              currency={currency}
            />
          }
        />
      </Box>

      <svg width={0} height={0}>
        <defs>
          <linearGradient
            id="totalAssetsChartGradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={get(theme, "colors.chart.0")}
              stopOpacity="1"
            />
            <stop
              offset="100%"
              stopColor={get(theme, "colors.chart.0")}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>

      <VictoryChart
        theme={CustomVictoryTheme}
        padding={{
          top: 24,
          bottom: 24,
        }}
        width={960}
        height={360}
        containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
      >
        <VictoryAxis
          scale={{ x: "time" }}
          tickFormat={(x) =>
            intl.formatDate(x, { month: "short", year: "2-digit" })
          }
          tickValues={generateTicks(timeSeries.length, 6, 2).map(
            (tick) => timeSeries[tick].x
          )}
        />

        <VictoryArea
          data={timeSeries}
          interpolation="monotoneX"
          style={{
            data: {
              fill: "url(#totalAssetsChartGradient)",
              opacity: 0.2,
            },
          }}
        />
        <VictoryLine
          data={timeSeries}
          interpolation="monotoneX"
          style={{
            data: {
              stroke: get(theme, "colors.chart.0"),
              strokeWidth: 4,
            },
          }}
        />
        <VictoryScatter
          data={timeSeries}
          style={{
            data: {
              fill: "#31445a",
              stroke: get(theme, "colors.chart.0"),
              strokeWidth: 2,
            },
          }}
          size={4}
          labels={({ datum }) =>
            `${intl.formatDate(datum.x)}: ${intl.formatNumber(datum.y, {
              style: "currency",
              currency: "BRL",
            })}`
          }
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </>
  );
}
