import { forwardRef } from "react";
import { Box, BoxProps } from "./Box";

export interface TextProps extends BoxProps {}

export interface TextKnownProps extends BoxProps {}

export interface TextProps
  extends TextKnownProps,
    Omit<React.HTMLProps<HTMLSpanElement>, keyof TextKnownProps> {}

export const Text = forwardRef<HTMLSpanElement, TextProps>((props, ref) => {
  return <Box ref={ref} as="span" tx="textStyles" {...props} />;
});
