import { Box, BoxProps } from "./Box";

export type DividerProps = BoxProps & {
  size?: number;
  color?: string;
  arrow?: boolean;
  arrowSize?: number;
};

export function Divider({
  size = 4,
  color = "#425974",
  arrow = false,
  arrowSize = 6,
}: DividerProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: size,
        backgroundColor: color,
        position: "relative",
        transition: "all 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
      }}
    >
      {arrow && (
        <Box
          sx={{
            position: "absolute",
            top: `-${arrowSize}px`,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            " > svg": {
              display: "block",
              " > path": {
                transition: "all 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
              },
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 11"
            height={arrowSize}
          >
            <path
              d="M11 1.7c-1.1 0-2.2.5-3 1.3l-8 8h22l-8-8a4.3 4.3 0 00-3-1.3z"
              fill={color}
            />
          </svg>
        </Box>
      )}
    </Box>
  );
}
