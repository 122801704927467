import { Account } from "../book";

export function getAccountFullName(
  account: Account,
  sep: string = "."
): string {
  // check if it is the root account or the parent is the root account
  if (!account.parentAccount || !account.parentAccount.parentAccount) {
    return account.name;
  }

  return getAccountFullName(account.parentAccount) + sep + account.name;
}
