import { parseStringPromise } from "xml2js";

import { Account } from "./models/account";
import { Book } from "./models/book";
import { Split } from "./models/split";
import { Transaction } from "./models/transaction";

export async function parseBook(xmlData: string): Promise<Book> {
  const xmlObject = await parseStringPromise(xmlData);
  const xml = xmlObject["gnc-v2"]["gnc:book"][0];

  const accountRefs = {};

  return {
    id: xml["book:id"][0]._,
    accounts: xml["gnc:account"].map((account: any) =>
      parseAccount(account, accountRefs)
    ),
    transactions: xml["gnc:transaction"].map((transaction: any) =>
      parseTransaction(transaction, accountRefs)
    ),
  };
}

function parseAccount(xml: any, accountRefs: any): Account {
  const parentAccountId = xml["act:parent"] && xml["act:parent"][0]._;

  const account = {
    id: xml["act:id"][0]._,
    name: xml["act:name"][0],
    type: xml["act:type"][0],
    parentAccount: parentAccountId ? accountRefs[parentAccountId] : null,
    commodity: {
      id: xml["act:commodity"][0]["cmdty:id"][0],
      space: xml["act:commodity"][0]["cmdty:space"][0],
    },
  };

  accountRefs[account.id] = account;

  return account;
}

function parseTransaction(xml: any, accountRefs: any): Transaction {
  return {
    id: xml["trn:id"][0]._,
    currency: {
      id: xml["trn:currency"][0]["cmdty:id"][0],
      space: xml["trn:currency"][0]["cmdty:space"][0],
    },
    datePosted: parseDate(xml["trn:date-posted"][0]),
    dateEntered: parseDate(xml["trn:date-entered"][0]),
    description: xml["trn:description"][0],
    splits: xml["trn:splits"][0]["trn:split"].map((split: any) =>
      parseSplits(split, accountRefs)
    ),
  };
}

function parseSplits(xml: any, accountRefs: any): Split {
  return {
    id: xml["split:id"][0]._,
    value: parseRational(xml["split:value"][0]),
    quantity: parseRational(xml["split:quantity"][0]),
    account: accountRefs[xml["split:account"][0]._],
  };
}

function parseDate(xml: any): Date {
  return new Date(xml["ts:date"][0]);
}

function parseRational(xml: string): number {
  if (xml.indexOf("/") < 0) {
    return parseFloat(xml);
  }

  const parts = xml.split("/");
  return parseInt(parts[0]) / parseInt(parts[1]);
}
