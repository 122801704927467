import { useEffect, useRef } from "react";

import { Box, Container, Button, Divider } from "ui";

export interface NavigationItem<T> {
  label: string;
  color?: string;
  value?: T;
}

export interface NavigationProps<T> {
  items: NavigationItem<T>[];
  activeIndex?: number;
  onNavigate?: (index: number) => void;
}

export function Navigation<T>({
  items,
  activeIndex,
  onNavigate,
}: NavigationProps<T>) {
  const containerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (typeof activeIndex !== "undefined") {
      const container = containerRef.current;
      const selected = container.children.item(
        activeIndex
      ) as HTMLButtonElement;
      const width = container.scrollWidth;
      const selectedWidth = selected.scrollWidth;
      const x = selected.offsetLeft + selectedWidth / 2;
      const offset = width / 2 - x;
      container.style.transform = `translate(${offset}px, 0)`;
    }
  }, [activeIndex]);

  const selectedItem =
    typeof activeIndex !== "undefined" ? items[activeIndex] : undefined;

  const dividerColor =
    selectedItem && selectedItem.color ? selectedItem.color : "#425974";

  return (
    <Container position="fixed" marginTop={4} zIndex={20}>
      <Box
        flexDirection="row"
        paddingTop={2}
        paddingBottom={1}
        justifyContent="center"
        backgroundColor="#2C3C4F"
        overflowX="hidden"
        position="relative"
        borderTopLeftRadius={12}
        borderTopRightRadius={12}
      >
        <Box
          ref={containerRef}
          flexDirection="row"
          flexShrink={0}
          position="relative"
          sx={{
            transition: "transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
          }}
        >
          {items.map((item, index) => (
            <Button
              key={index}
              variant="link"
              marginX={1}
              onClick={() => onNavigate && onNavigate(index)}
              color={item.color || "#5f7b9c"}
            >
              {item.label}
            </Button>
          ))}
        </Box>

        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          background={`linear-gradient(to right, 
            #2C3C4F 0%,
            #2C3C4F 5%,
            #00000000 25%,
            #00000000 75%,
            #2C3C4F 95%,
            #2C3C4F 100%)`}
          sx={{
            pointerEvents: "none",
          }}
        />
      </Box>

      <Divider color={dividerColor} arrow arrowSize={6} />
    </Container>
  );
}
