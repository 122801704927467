import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/app";

const auth = firebase.auth();

export function useAuth() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<firebase.User | null>(null);

  const signIn = useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithPopup(provider);
  }, []);

  const signOut = useCallback(() => auth.signOut(), []);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
  });

  return { loading, user, signIn, signOut };
}
